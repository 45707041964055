<template>
  <Modal v-model="createBannerModal" width="800" title="新增">
    <Form
      ref="bannerInfo"
      :model="bannerInfo"
      autocomplete="on"
      name="oohforce-device-add"
      id="oohforce-device-add"
      label-position="top"
    >
      <Row :gutter="8" class="m-b-10">
        <i-col span="18"  class="m-t-10">
          <FormItem label="画面描述">
            <Input
              v-model="bannerInfo.remark"
              type="textarea"
              placeholder="画面描述"
            />
          </FormItem>
        </i-col>
        <i-col span="3"  class="m-t-10">
        </i-col>
        <i-col span="3"  class="m-t-10">
          <FormItem label="是否启用">
          <i-switch size="large" v-model="status">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
        </i-col>
        <i-col span="24"  class="m-t-10">
          <FormItem label="BANNER画面">
            <Upload
              ref="upload"
              :with-credentials="true"
              :action="action"
              :headers="uploadHeaders"
              :data="data"
              :show-upload-list="false"
              :format="format"
              :max-size="maxSize"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleExceedMaxsize"
              :on-success="handleFileUploadSuccess"
              type="drag"
            >
              <div style="padding: 20px 0">
                <Icon
                  type="ios-cloud-upload"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <p>点击或拖拽文件到这里进行上传</p>
              </div>
              <!-- <slot name="upload_btn">
        <div>
          <a >添加文件并上传</a>
        </div>
      </slot> -->
            </Upload>

            <div class="file-container">
              <div v-if="uploadFiles.length > 0">
                <h4 class="text-green p-t-10">最新上传文件</h4>
                <Row v-for="(file, i) in uploadFiles" :key="i">
                  <template v-if="file.status === 'finished'">
                    <i-col span="16">
                      {{ file.name }}
                    </i-col>
                    <i-col span="8">
                      <a
                        href="javascript:void(0)"
                        @click="handleDownload(file.response)"
                        >下载</a
                      >
                      <a
                        href="javascript:void(0)"
                        class="m-l-5"
                        style="color: #ef4f4f"
                        @click="handleRemove(file.response,i)"
                        >删除</a
                      >
                    </i-col>
                  </template>
                  <template v-else>
                    <i-col span="12">
                      <Progress
                        v-if="file.showProgress"
                        :percent="file.percentage"
                      ></Progress>
                      <span v-else>{{ file.name }}</span>
                    </i-col>
                    <!-- <i-col span="12">
                      <i-button
                        icon="ios-trash"
                        class="m-l-5"
                        type="error"
                        @click="handleRemove(file)"
                        >删除</i-button
                      >
                    </i-col> -->
                  </template>
                </Row>
              </div>
<!--
              <div v-if="existsFile.length > 0">
                <Divider dashed>已有文件</Divider>
                <i-table
                  stripe
                  :data="existsFile"
                  :columns="fileColumns"
                ></i-table>
              </div> -->
            </div>
            <!-- 上传描述，备注信息 -->
            <div class="tips m-l-10">
              <ul>
                <li>您只能上传后缀名为 {{ format.toString() }} 格式的文件</li>
                <li>文件大小不能超过 {{ maxSize / 1024 }} MB</li>
              </ul>
            </div>
          </FormItem>
        </i-col>
      </Row>
    </Form>

    <div slot="footer">
      <Button type="text" class="m-r-5" @click="createBannerModal = false"
        >关闭</Button
      >
      <Button type="primary" @click="handleConfirmPublicity"
      :loading="!allowSubmit">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'

import { createOrUpdate } from '@/api/msp/banner'
import { signurlByAttach, removeFileAttach } from '@/api/resource/endpoint'
import { getStorage } from '@/utils/storage'
import { toMoney } from '@/utils/wnumb_own'
export default {
  props: {
  },
  data () {
    return {
      orderInfo: {},

      createBannerModal: false,
      status: true,
      bannerInfo: {
        status: 2,
        fileId: '',
        remark: ''
      },

      data: { code: 1 },
      uploadList: [],
      action: process.env.VUE_APP_API_URL_V2 + '/ooh-resource/oss/endpoint/put-file-attach',
      visible: false,
      uploadHeaders: {
        'ooh-auth': getStorage('ooh-token')
      },
      format: ['jpg', 'jpeg', 'png'],
      maxSize: 204800,

      allowSubmit: true,
      uploadFiles: []

    }
  },
  mounted () {},
  methods: {

    formatMoney (number) {
      return toMoney(number)
    },
    showModal () {
      this.uploadFiles = []
      this.bannerInfo.remark = ''
      this.status = true
      this.createBannerModal = true
    },

    hideModal () {
      this.createBannerModal = false
    },

    handleExceedMaxsize (file) {
      this.$Notice.warning({
        title: '已经超过了文件大小限制',
        desc: '文件  ' + file.name + ' 过大，不能超过' + this.maxSize + 'Kb'
      })
    },
    handleFormatError (file) {
      this.$Notice.warning({
        title: '文件类型不正确',
        desc: '文件  ' + file.name + ' 的类型只能是' + this.format.toString()
      })
    },
    handleFileUploadSuccess (response, file) {
      if (response.success) {
        this.uploadFiles = [file]
      }
    },
    handleDownload (file) {
      signurlByAttach({ attachId: file.data.id }).then(res => {
        downloadFileRequest(res.data, { }, file.data.originalName)
      })
    },
    handleRemove (file, index) {
      removeFileAttach({ attachId: file.data.id }).then(res => {
        this.uploadFiles = []
      })
    },
    // 确认保存
    handleConfirmPublicity () {
      const that = this

      if (that.uploadFiles.length) {
        that.bannerInfo.filePath = that.uploadFiles[0].response.data.link
      } else {
        that.$Notice.success({ desc: '请先上传BANNER图片' })
        return
      }
      that.bannerInfo.status = that.status ? 2 : 1

      that.allowSubmit = false
      createOrUpdate(that.bannerInfo).then(res => {
        if (res && res.errcode === 0) {
          that.$Notice.success({ desc: '操作成功' })
          that.hideModal()
          that.$emit('on-success')
        }

        that.allowSubmit = true
      })
    }
  },
  watch: {},
  computed: {
  }
}
</script>
