import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取任务冲突或关联任务集合
 * @param {Object} data
 */
export function taskitemList (data) {
  return request(
    {
      url: '/ooh-msp/v1/conflict/taskitemlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 创建或更新案例banner素材
 * @param {Object} data
 */
export function createOrUpdate (data) {
  return request(
    {
      url: '/ooh-msp/v2/banner/createOrUpdate',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 分页获取文件列表
 * @param {Object} data
 */
export function getFilePage (data) {
  return request(
    {
      url: '/ooh-msp/v2/banner/getFilePage',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 更新案例库文件状态
 * @param {Object} data
 */
export function updateStatus (data) {
  return request(
    {
      url: '/ooh-msp/v2/banner/updateStatus',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
